<template>
  <!-- select 2 demo -->
  <b-modal
    id="modal-select-section"
    title="Select section"
    ok-title="Load"
    cancel-variant="outline-secondary"
    @shown="getAllSections"
    @ok="loadSection"
  >
    <b-form>
      <b-form-group
        label="Choose the section"
        label-for="Choose the section"
      >
        <v-select
          id="section"
          v-model="selectedSection"
          label="Name"
          :options="option"
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>

import {
  BModal, VBModal, BForm, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import sectionService from '@core/services/sections/sectionsService'
import catalogService from '@/@core/services/catalogs/catalogsService'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedSection: '',
      option: ['USA', 'Canada', 'Maxico'],
    }
  },
  methods: {
    getAllSections() {
      catalogService.getAllElementCatalog(
        { table: 'section' },
      ).then(response => {
        this.option = response.data.data
      })
    },
    loadSection(e) {
      e.preventDefault()
      sectionService.getSection({ id: this.selectedSection.Id }).then(({ data }) => {
        const { sectionQuestions: [section] } = data
        section.editable = this.selectedSection.Editable
        this.sections.push(section)
        this.$root.$emit('bv::hide::modal', 'modal-select-section')
      })
    },
  },
}
</script>

<style>

</style>
