import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class SectionService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getAllSections(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getAllSections, ...args)
    }

    createSection(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.createSection, ...args)
    }

    updateSection(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.updateSection, ...args)
    }

    getSection(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getSection, ...args)
    }

    deleteSection(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.deleteSection, ...args)
    }
}

function useSectionService() {
  const sectionService = new SectionService()

  return {
    sectionService,
  }
}

const { sectionService } = useSectionService()
export default sectionService
