import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class CategoryService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getCategories(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getCategories, ...args)
    }

    insertCategory(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.insertCategory, ...args)
    }
}

function useCategoryService() {
  const categoryService = new CategoryService()

  return {
    categoryService,
  }
}

const { categoryService } = useCategoryService()
export default categoryService
