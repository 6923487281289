<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Evaluation Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="mt-0">
                <b-row>
                  <b-col
                    cols="12"
                    xl="8"
                  >
                    <div>
                      <h3 class="text-primary invoice-logo">
                        {{ NameEvaluation != '' ? NameEvaluation : 'Evaluation Name' }}
                      </h3>
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                  >
                    <b-row
                      class="d-flex mb-1"
                      style="justify-content: end;"
                    >
                      <div
                        class="d-flex"
                        style="justify-content: end;"
                      >
                        <feather-icon
                          icon="CheckSquareIcon"
                          size="20"
                          class="mr-1"
                        />
                        <div class="font-weight-bold">
                          {{ totalEval }} %
                        </div>
                      </div>
                    </b-row>
                    <b-row
                      class="d-flex"
                      style="justify-content: end;"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="primary"
                        @click="distributePctSections(sections)"
                      >
                        Distribute %
                      </b-button>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="pt-0"
            >
              <b-row class="invoice-spacing">
                <!-- Col: Instructions -->
                <b-col
                  v-if="Instructions"
                  cols="12"
                  xl="4"
                >
                  <h6 class="mb-2">
                    Instructions to commit this evaluation:
                  </h6>
                  <div
                    class="mt-1"
                  >
                    <b-card-text class="mb-25">
                      {{ Instructions }}
                    </b-card-text>
                  </div>
                </b-col>
                <!-- Col: Category Details -->
                <b-col
                  v-if="SelectedCategory"
                  xl="4"
                  cols="12"
                  class="mt-xl-0 mt-2"
                >
                  <div>
                    <h6 class="mb-2">
                      Category Details:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Name:
                          </td>
                          <td><span class="font-weight-bold">{{ SelectedCategory.Name }}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Description:
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-card-text class="font-weight-bold">
                      Category Description is gonna be show in this place
                    </b-card-text>
                  </div>
                </b-col>
                <!-- Col: Staff Details -->
                <b-col
                  v-if="SelectedStaff"
                  xl="4"
                  cols="12"
                  class="mt-xl-0 mt-2"
                >
                  <div>
                    <h6 class="mb-2">
                      Staff Details:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Total:
                          </td>
                          <td><span class="font-weight-bold">{{ SelectedStaff.length }}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
              <b-row
                class="d-flex invoice-spacing"
                style="justify-content: end;"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="repeateAgain"
                >
                  Add Section
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.modal-select-section
                  size="sm"
                  variant="primary"
                >
                  Load Section
                </b-button>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
              >
                <app-collapse
                  accordion
                  type="magin"
                >
                  <draggable
                    v-model="sections"
                    tag="ul"
                    class="list-group list-group-flush cursor-move"
                  >
                    <transition-group
                      type="transition"
                      name="flip-list"
                    >
                      <app-collapse-item
                        v-for="(section, index) in sections"
                        :key="`section_${index}`"
                        ref="row"
                        :title="`section ${section.title}`"
                        class="mb-1"
                      >
                        <template #header>
                          <!-- Title -->
                          <b-col md="3">
                            <b-form-group>
                              <b-form-input
                                v-model="section.title"
                                placeholder="Title"
                                rows="3"
                                no-resize
                                @click.stop
                              />
                            </b-form-group>
                          </b-col>
                          <!-- Description -->
                          <b-col md="6">
                            <b-form-group>
                              <b-form-input
                                v-model="section.description"
                                placeholder="Description"
                                rows="3"
                                no-resize
                                @click.stop
                              />
                            </b-form-group>
                          </b-col>
                          <!-- percentaje -->
                          <b-col
                            md="2"
                            @click.stop
                          >
                            <b-form-group>
                              <b-form-spinbutton
                                v-model="section.value"
                                min="0"
                                max="100"
                                wrap
                              />
                            </b-form-group>
                          </b-col>
                          <!-- delete button -->
                          <b-col
                            lg="1"
                            md="1"
                          >
                            <b-form-group>
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                class="btn-icon"
                                @click="removeSection(index)"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                />
                              </b-button>
                            </b-form-group>
                          </b-col>
                        </template>
                        <!-- Spacer -->
                        <hr class="invoice-spacing">
                        <b-row
                          class="d-flex invoice-spacing"
                          style="justify-content: end;"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            size="sm"
                            variant="primary"
                            @click="distributePctQuestions(section.questions)"
                          >
                            Distribute %
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            size="sm"
                            variant="primary"
                            @click="repeateQuestion(section)"
                          >
                            Add Question
                          </b-button>
                        </b-row>
                        <draggable
                          v-model="section.questions"
                          tag="ul"
                          class="list-group list-group-flush cursor-move"
                        >
                          <transition-group
                            key=""
                            type="transition"
                            name="flip-list"
                          >
                            <b-row
                              v-for="(question, indexQuestion) in section.questions"
                              :key="`question_${indexQuestion}`"
                              class="mx-auto"
                            >
                              <template
                                v-if="question.status===1"
                              >
                                <!-- Concept -->
                                <b-col md="3">
                                  <b-form-group
                                    label="Concept"
                                    label-for="Concept"
                                  >
                                    <b-form-input
                                      v-model="question.concept"
                                      placeholder="Concept"
                                      rows="3"
                                      no-resize
                                    />
                                    <b-form-checkbox
                                      v-model="question.activeRating"
                                      class="mt-1"
                                    >
                                      Enable rating
                                      <b-form-rating
                                        v-if="question.activeRating"
                                        no-border
                                        show-clear
                                        class="w-auto pt-0 p-0 alignment-forced"
                                        variant="warning"
                                        inline
                                        readonly
                                        precision="2"
                                      />
                                    </b-form-checkbox>
                                  </b-form-group>
                                </b-col>

                                <!-- Description -->
                                <b-col md="6">
                                  <b-form-group
                                    label="Description"
                                    label-for="Description"
                                  >
                                    <b-form-textarea
                                      v-model="question.description"
                                      placeholder="Description"
                                      rows="3"
                                      no-resize
                                      style="height: 38px;"
                                    />
                                    <b-form-checkbox
                                      v-model="question.isNotApplicable"
                                      class="mt-1"
                                    >
                                      This question is not applicable to all cases
                                    </b-form-checkbox>
                                  </b-form-group>
                                </b-col>
                                <!-- Percentaje -->
                                <b-col md="2">
                                  <label>value</label>
                                  <b-form-spinbutton
                                    v-model="question.value"
                                    min="0"
                                    max="100"
                                    wrap
                                  />
                                  <div style="display: flex; align-items: center; margin-top: 10px;">
                                    <b-form-group>
                                      <b-form-checkbox
                                        v-model="question.isNegative"
                                      >
                                        Invert value
                                      </b-form-checkbox>
                                    </b-form-group>
                                  </div>
                                </b-col>
                                <!-- Remove Button -->
                                <b-col
                                  lg="1"
                                  md="1"
                                >
                                  <b-button
                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                    variant="outline-danger"
                                    class="mt-0 mt-md-2 btn-icon"
                                    @click="removeQuestion(section, indexQuestion)"
                                  >
                                    <feather-icon
                                      icon="Trash2Icon"
                                    />
                                  </b-button>
                                </b-col>
                              </template>

                            </b-row>
                          </transition-group>
                        </draggable>
                      </app-collapse-item>
                    </transition-group>
                  </draggable>
                </app-collapse>
              </div>
            </b-card-body>

          </b-card>
        </b-form>
      </b-col>
      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <b-card>
          <div>

            <b-form-group
              label="Evaluation Name"
              label-for="Evaluation Name"
              description="The name of your evaluation will be an identifier"
            >
              <b-form-input
                id="input-formatter"
                v-model="NameEvaluation"
                placeholder="Enter evaluation name"
              />
            </b-form-group>

            <b-form-group
              label="Category"
              label-for="Category"
              description="The evaluation will have a specific category to be able to be located"
            >
              <v-select
                v-model="SelectedCategory"
                :options="Categories"
                label="Name"
                class="payment-selector"
                input-id="invoice-data-client"
              >
                <template #list-header>
                  <li
                    v-b-toggle.sidebar-add-new-category
                    class="add-new-client-header d-flex align-items-center my-50"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                    />
                    <span class="align-middle ml-25">Add New Category</span>
                  </li>
                </template>
              </v-select>
            </b-form-group>

            <b-form-group
              label="Staff"
              label-for="Staff"
              description="This evaluation will be applied to all selected Staffs"
            >
              <v-select
                v-model="SelectedStaff"
                :options="Staff"
                label="Name"
                input-id="payment-method"
                class="payment-selector style-chooser"
                :clearable="true"
                multiple
              >
                <template #list-header>
                  <li
                    v-b-toggle.sidebar-add-new-staff
                    class="add-new-client-header d-flex align-items-center my-50"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                    />
                    <span class="align-middle ml-25">Add New Staff</span>
                  </li>
                </template>
              </v-select>
            </b-form-group>

            <b-form-group
              label="Evaluation Tags"
              label-for="Evaluation Tags"
            >
              <b-form-tags
                v-model="tags"
                input-id="Evaluation Tags"
                class="mb-2"
              />
            </b-form-group>

            <b-form-group
              label="Instructions"
              label-for="Instructions"
            >
              <b-form-textarea v-model="Instructions" />
            </b-form-group>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-75"
              block
              @click="SaveEvaluation"
            >
              Save
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <sidebar-add-new-category :refresh-data="getCategories" />
    <sidebar-add-new-staff :refresh-data-staff="getAllStaffs" />
    <load-section
      :sections="sections"
    />
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BFormTextarea, VBToggle, BFormSpinbutton, BFormCheckbox, BFormRating, BFormTags,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import evaluationsService from '@core/services/evaluations/evaluationService'
import categoryService from '@core/services/categories/categoryService'
import staffService from '@core/services/staff/staffServices'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import SidebarAddNewCategory from './Sidebar/SidebarAddNewCategory.vue'
import SidebarAddNewStaff from './Sidebar/SidebarAddNewStaff.vue'
import LoadSection from '@/@core/components/modals/sections/LoadSection.vue'

export default {
  components: {
    BRow,
    AppCollapse,
    AppCollapseItem,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    BFormSpinbutton,
    SidebarAddNewCategory,
    SidebarAddNewStaff,
    BFormTags,
    draggable,
    BFormCheckbox,
    BFormRating,
    LoadSection,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data() {
    return {
      sections: [],
      deletedSections: [],
      items: [],
      nextTodoId: 0,
      NameEvaluation: '',
      tags: [],
      Instructions: '',
      SelectedStaff: null,
      totalEval: 0,
      Staff: [],
      SelectedCategory: null,
      Categories: [],
      IdEval: 0,
      IdProfileSelected: 0,
    }
  },
  watch: {
    sections: {
      handler(sections) {
        let total = 0
        sections.forEach(section => {
          if (section.value) total += section.value
        })
        this.totalEval = total
      },
      deep: true,
    },
  },
  mounted() {
    this.initTrHeight()
    this.getCategories()
    this.getAllStaffs()
    this.checkEvaluation()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    /* eslint-disable */
    getCategories() {
      categoryService.getCategories().then(response => {
        this.Categories = response.data.data
      })
    },
    getAllStaffs() {
      staffService.getAllStaff().then(response => {
        this.Staff = response.data.data
      })
    },
    distributePctSections(list){
      const size = list.length || 0
      if (!size) return
      let ptc = 100
      const weight = Math.floor(ptc/size)
      list.forEach(item => {
        item.value = weight
        ptc -= weight
      })
      if(ptc > 0) list[0].value = ptc + weight
    },
    distributePctQuestions(list){
      const filteredList = list.filter( item => item.status === 1)
      const size = filteredList.length || 0
      if (!size) return
      let ptc = 100
      const weight = Math.floor(ptc/size)
      filteredList.forEach(item => {
        item.value = weight
        ptc -= weight
      })
      if(ptc > 0) filteredList[0].value = ptc + weight
    },
    /* eslint-enable */
    repeateAgain() {
      this.sections.push({
        indicator: this.nextTodoId += 1,
        id: 0,
        title: '',
        description: '',
        value: 0,
        questions: [
          {
            id: 0,
            idQuestion: '',
            value: '',
            concept: '',
            description: '',
            activeRating: '',
            isNotApplicable: false,
            isNegative: false,
          },
        ],
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    repeateQuestion(section) {
      section.questions.push({
        id: 0,
        idQuestion: '',
        value: '',
        concept: '',
        description: '',
        activeRating: '',
        isNotApplicable: false,
        isNegative: false,
        status: 1,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeSection(index) {
      this.sections.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    /* eslint-disable no-param-reassign */
    removeQuestion(section, index) {
      if (section.questions[index].id > 0) section.questions[index].status = 0
      else section.questions.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    /* eslint-enable no-param-reassign */
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    checkEvaluation() {
      const Edit = this.$store.getters['evaluations/getIdEvaluation'] // TODO: SAVE VUEX
      this.IdProfileSelected = this.$store.getters['profiles/getIdProfile']
      if (Edit > 0) {
        this.IdEval = Edit
        evaluationsService.getEvaluationById({
          id: this.IdEval,
          idUser: 0,
        }).then(({ data }) => {
          const {
            evalBasicData, evalCategory, evalStaff, evalQuestions,
          } = data
          this.NameEvaluation = evalBasicData.EvaluationName
          this.Instructions = evalBasicData.EvaluationDescription
          this.SelectedCategory = evalCategory
          this.SelectedStaff = evalStaff
          this.sections = evalQuestions
          this.tags = evalBasicData.EvaluationTags.split('~') || []
        })
      }
    },
    /* eslint-disable */
    SaveEvaluation() {
      /* this.NameEvaluation
      this.SelectedCategory
      this.SelectedStaff */
      let total = 0
      const weighingValues = this.sections.map(({ value }) => ({ value }))
      weighingValues.forEach(weight => total += weight.value)
      this.totalEval = total

      const sections = this.sections.map(section => Boolean(section.value && section.title && section.description))
      const empty = this.allTrue(sections)

      if (total !== 100) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Your sections value exceed or don´t reach the 100%',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      } else if (!empty) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Missing field in the form',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      } else {

        if (this.IdEval > 0) {
          Swal.fire({
            title: 'Attention',
            text: ' ¿Do you want to Edit this evaluation?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Save',
          }).then(result => {
            if (result.isConfirmed) {
              const staffs = Object.values(this.SelectedStaff).map(data => data.Id)
              evaluationsService.updateEvaluation({
                idEvaluation: this.IdEval,
                title: this.NameEvaluation,
                description: this.Instructions,
                category: this.SelectedCategory.Id,
                staff: staffs,
                sections: this.sections,
                tags: this.tags.join('~'),
              }).then(() => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Your Form has been edit',
                  showConfirmButton: false,
                })
                this.$router.push({ path: '/home', params: {} })
              })
            }
          })
        } else {
          Swal.fire({
            title: 'Attention',
            text: ' ¿Do you want to create this evaluation?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Save',
          }).then(result => {
            if (result.isConfirmed) {
              const staffs = Object.values(this.SelectedStaff).map(data => data.Id)
              evaluationsService.createEvaluation({
                title: this.NameEvaluation,
                description: this.Instructions,
                category: this.SelectedCategory.Id,
                staff: staffs,
                sections: this.sections,
                tags: this.tags.join('~'),
              }).then(() => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Your Form has been save',
                  showConfirmButton: false,
                })
                this.$router.push({ path: '/home', params: {} })
              })
            }
          })
        }
        
      }
    },
    allTrue(obj) {
      for (const o in obj) if (!obj[o]) return false
      return true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style>
.flatpickr-calendar .flatpickr-day.inRange, .flatpickr-calendar .flatpickr-day.inRange:hover{
  color: white;
}
.list-group-row {
  transition: all 1s
}
.style-chooser .vs__deselect {
  color: white !important;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.alignment-forced {
  align-items: normal !important;
}
</style>
