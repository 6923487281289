<template>
  <b-sidebar
    id="sidebar-add-new-category"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="cleanCategorySidebar"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Category
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <!-- Customer Name -->
        <b-form-group
          label="Category Name"
          label-for="Category-name"
        >
          <b-form-input
            v-model="NameCategory"
            trim
            placeholder="Category Name"
          />
        </b-form-group>

        <!-- Email -->
        <!-- <b-form-group
          label="Description"
          label-for="Description"
        >
          <b-form-input
            v-model="DescriptionCategory"
            trim
            placeholder="Description"
          />
        </b-form-group> -->

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="SaveCategory();"
          >
            Add
          </b-button>
          <b-button
            ref="closeSideBarCategory"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import categoryService from '@core/services/categories/categoryService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      NameCategory: '',
      DescriptionCategory: '',
    }
  },
  methods: {
    SaveCategory() {
      if (this.NameCategory !== '') {
        Swal.fire({
          title: 'Attention',
          text: ' ¿Do you want to create category?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Create',
        }).then(result => {
          if (result.isConfirmed) {
            categoryService.insertCategory({
              name: this.NameCategory,
            }).then(() => {
              this.refreshData()
              this.$refs.closeSideBarCategory.click()
            })
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Insert a Category Name',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    cleanCategorySidebar() {
      this.NameCategory = ''
      this.DescriptionCategory = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
